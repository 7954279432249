button.styled {
  height: 43px;
  background: var(--main-color);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 7px;
  font-family: "Noto Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  line-height: 16px;
  border: none;
  color: #ffffff;
  margin-left: 10px;
  margin-right: 10px;
  cursor: pointer;
}

button[id$="CloseOrCancelButton"].cancel,
button[id$="DeleteOrSaveButton"].save {
  border: 3px solid var(--main-color-darker);
}

button.list-action {
  margin-right: 0.3em;
  padding-right: 0.3em;
  padding-left: 0.3em;
  min-width: 5em;
}

button.styled.active {
  background: gray;
}

button.nav-selected {
  background: var(--green);
}
